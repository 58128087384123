import "./style.css";
import icon from "./github-black.svg";

const BtnGithub = ({link}) => {
    return ( 
        <a href={link} target="_blank" rel="noreferrer" className="btn-outline">
            <img src={icon} alt="Github icon" />
            GitHub repo
        </a>
     );
}
 
export default BtnGithub;