import project01 from "./../img/projects/01.jpg";
import project01Big from "./../img/projects/01-big.jpg";

import project02 from "./../img/projects/02.jpg";
import project02Big from "./../img/projects/02-big.jpg";

import project03 from "./../img/projects/03.jpg";
import project03Big from "./../img/projects/03-big.jpg";

import project04 from "./../img/projects/04.jpg";
import project04Big from "./../img/projects/04-big.jpg";

import project05 from "./../img/projects/05.jpg";
import project05Big from "./../img/projects/05-big.jpg";

import project06 from "./../img/projects/06.jpg";
import project06Big from "./../img/projects/06-big.jpg";

const projects = [
    {
        title: 'Gaming streaming portal',
        skills: 'React, Node.js, MongoDB',
        img: project01,
        imgBig: project01Big,
        githubLink: 'https://github.com',
    },
    {
        title: 'Video service',
        skills: 'React, PHP, MySQL',
        img: project02,
        imgBig: project02Big,
        githubLink: 'https://github.com',
    },
    {
        title: 'Video portal',
        skills: 'React, PHP, MySQL',
        img: project03,
        imgBig: project03Big,
    },
    {
        title: 'Dating app',
        skills: 'React, PHP, MySQL',
        img: project04,
        imgBig: project04Big,
        githubLink: 'https://github.com',
    },
    {
        title: 'Landing',
        skills: 'React, PHP, MySQL',
        img: project05,
        imgBig: project05Big,
    },
    {
        title: 'Gaming community',
        skills: 'React, PHP, MySQL',
        img: project06,
        imgBig: project06Big,
        githubLink: 'https://github.com',
    },
]

export {projects}