import "./style.css";

import github from "./../../img/icons/github.svg";
import instagram from "./../../img/icons/instagram.svg";
import linkedin from "./../../img/icons/linkedin.svg";
import twitter from "./../../img/icons/twitter.svg";
import vk from "./../../img/icons/vk.svg";

const Footer = () => {
    return ( 
        <footer className="footer">
            <div className="container">
              <div className="footer__wrapper">
                <ul className="social">
                  <li className="social__item">
                    <a href="#!">
                        <img src={vk} alt="VK" />
                    </a>
                  </li>
                  <li className="social__item">
                    <a href="#!">
                        <img src={instagram} alt="Instagram" />
                    </a>
                  </li>
                  <li className="social__item">
                    <a href="#!">
                        <img src={twitter} alt="Twitter" />
                    </a>
                  </li>
                  <li className="social__item">
                    <a href="#!">
                        <img src={github} alt="Github" />
                    </a>
                  </li>
                  <li className="social__item">
                    <a href="#!">
                        <img src={linkedin} alt="Linkedin" />
                    </a>
                  </li>
                </ul>
                <div className="copyright">
                  <p>© 2022 frontend-dev.com</p>
                </div>
              </div>
            </div>
        </footer>
     );
}
 
export default Footer;